.app-content {
  max-width: 800px;
  z-index: 1;
  text-align: left;
  width: 95%;
  line-height: 1.5em;
  /* margin: 4% 1%; */
  padding: 4%;
}

.app-header {
  color: rgb(130, 54, 230);
}

.about-me {
  margin: 1em 0em;
}

.collapsible-comp {
  margin: 20px 0px;
  padding: 10px;
  border: 2px solid #f5edff;
  border-radius: 10px;
  background-color: #f7f7f76b;
}

.collapsible-button{
  width: 100%;
  cursor: pointer;
  background-color: transparent;
  font: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;

}

.collapsible-title-heading {
  margin: 0%;
}

.collapsible-description {
  margin: 5px;
}

.collapsible-description-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collapsible-description-company {
  text-align: left;
}

.collapsible-description-duration{
  text-align: right;
}

.content-container {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  height: 100%;
  z-index: 1;
  text-align: left;
  /* width: 95%; */
  line-height: 1.5em;
  /* margin: 4% 1%; */
  padding: 4%;
}

.desc-item {
  display: block;
}

/* .experience {
  
} */

.experience-details {
  margin: 0px 10px;
}

.experience-details-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company {
  font-weight: normal;
}

.footer {
  font-size: 0.9em;
  margin: 15px 0px 10px 0px;
  text-align: center;
}

.line-break {
  margin: 0.5em 0em;
  border: 1.5px solid #e6ddff;
  border-radius: 1px;
}

.lottie-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  /* overflow: hidden; */
}

.main-container {
  position: relative;
  z-index: -2;
}

.social-gh, .social-lc, .social-li {
  color: rgb(117, 74, 255);
  text-decoration: none;
  border-bottom: 1px solid;
  margin: 0px 5px;
  padding: 0px 5px;
}

p {
  margin-block-start: .2em;
  margin-block-end: .2em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

h1 {
  font-weight: 700;
}

h2 {
  font-weight: 600;
}

h3 {
  font-weight: 500;
}